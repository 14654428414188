import { Box, IconButton, Drawer, Modal, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColourModeContext, tokens } from "../../../theme";
// import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MenuIcon from "@mui/icons-material/Menu";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import SearchIcon from "@mui/icons-material/Search";
// import AddCardIcon from "@mui/icons-material/AddCard";
// import { getCredentials } from "../../../data/fetchCognito";
// import { getLinkToken, exchangePublicToken } from "../../../data/plaiddata";
// import {
//   usePlaidLink,
//   PlaidLinkOptions,
//   PlaidLinkOnSuccess,
// } from "react-plaid-link";

// import { useState, useEffect } from "react";
import PlaidLink from "../../../components/PlaidLink";
import SettingsModal from "../../../components/settingModal";
import DrawerMenu from "./Drawer";

const Topbar = ({ showBurger, setLoading }) => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  const colourMode = useContext(ColourModeContext);
  const [openDrawer, setOpenDrawer] = useState(false);

  const removeDrawer = () => {
    setOpenDrawer(false);
  };

  const makeDrawer = () => {
    console.log(openDrawer);
    setOpenDrawer(true);

    console.log(openDrawer);
  };

  const settingModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: `${colours.primary[400]}`,
    border: `2px solid ${colours.primary[600]}`,
    boxShadow: 24,
    p: 2,
    borderRadius: "5px",
    height: "50vh",
    minWidth: "600px",
  };

  const [settingsOpen, setSettingsOpen] = useState(false);
  const handleSettingsOpen = () => setSettingsOpen(true);
  const handleSettingsClose = () => setSettingsOpen(false);

  useEffect(() => {
    if (!showBurger) setOpenDrawer(false);
  }, [showBurger]);

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      {/* <Box
        display="flex"
        backgroundColor={colours.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box> */}
      {showBurger && (
        <IconButton onClick={makeDrawer}>
          <MenuIcon />
        </IconButton>
      )}

      {showBurger && (
        <Drawer
          open={openDrawer}
          onClose={removeDrawer}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: `${colours.primary[400]} !important`,
              padding: "5px 35px 5px 20px !important",
            },
          }}
        >
          <DrawerMenu />
        </Drawer>
      )}
      <Box display="flex" marginLeft="auto">
        <PlaidLink setLoading={setLoading} />
        <IconButton onClick={colourMode.toggleColourMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        <IconButton onClick={handleSettingsOpen}>
          <SettingsOutlinedIcon />
        </IconButton>
        {/* <IconButton>
          <PersonOutlinedIcon />
        </IconButton> */}
      </Box>
      <Modal
        open={settingsOpen}
        onClose={handleSettingsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={settingModalStyle}>
          <SettingsModal />
        </Box>
      </Modal>
    </Box>
  );
};

export default Topbar;
