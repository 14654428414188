import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { Amplify } from "aws-amplify";
import awsExports from "../../aws-exports";

import Info from "./pages/info";
import AccountSettings from "./pages/accounts";

Amplify.configure(awsExports);

const Item = ({ title, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{ color: colours.gray[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Info");
  return (
    <Box display="flex" justifyContent="left" marginTop="25px">
      {/* <div className="app"> */}
      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colours.primary[400]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
          },
          "& .pro-inner-item:hover": {
            color: "#868dfb !important",
          },
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
          },
        }}
      >
        <ProSidebar collapsed={false} width="200px">
          <Menu iconShape="square">
            {/* Menu Items */}
            <Box>
              <Item
                title="Info"
                icon={<PersonOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Bank Accounts"
                icon={<AccountBalanceOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
      {/* </div> */}

      <div className="content">
        {selected === "Info" && <Info />}
        {selected === "Bank Accounts" && <AccountSettings />}
      </div>
    </Box>
  );
};

export default Sidebar;
