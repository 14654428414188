import { ColourModeContext, useMode } from "./theme";
import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator } from "@aws-amplify/ui-react";

import Home from "./pages/home";
// import Login from "./pages/login/pages/login";
// import Signup from "./pages/login/pages/signup";
import awsExports from "./aws-exports";
import Info from "./components/settingModal/pages/info";
import Loading from "./pages/home/loading";

Amplify.configure(awsExports);

function App() {
  const [theme, colourMode] = useMode();
  // const colours = tokens(theme.palette.mode);

  return (
    <ColourModeContext.Provider value={colourMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            body:
              theme.palette.mode === "dark"
                ? {
                    background: `linear-gradient(-45deg, #1e1e1e, #2c3e50, #34495e, #2c3e50)`,
                    animation: "gradientBackground 15s ease infinite",
                    // backgroundSize: "400% 400%",
                  }
                : "",
          }}
        />
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/test" element={<Info />} />
          <Route path="/loading" element={<Loading />} />

          {/* <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} /> */}
        </Routes>
      </ThemeProvider>
    </ColourModeContext.Provider>
  );
}

// import Topbar from "./pages/home/global/Topbar";
// import Dashboard from "./pages/home/dashboard";
// import Sidebar from "./pages/home/global/Sidebar";
// import Team from "./pages/home/team";
// import Invoices from "./pages/home/invoices";
// import Contacts from "./pages/home/contacts";
// import Bar from "./pages/home/bar";
// import Form from "./pages/home/form";
// import Line from "./pages/home/line";
// import Pie from "./pages/home/pie";
// import FAQ from "./pages/home/faq";
// import Geography from "./pages/home/geography";
// import Calendar from "./pages/home/calendar";

// function App() {
//   const [theme, colourMode] = useMode();
//   return (
//     <ColourModeContext.Provider value={colourMode}>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />

//         <div className="app">
//           <Sidebar />

//           <main className="content">
//             <Topbar />
//             <Routes>
//               <Route path="/" element={<Dashboard />} />
//               <Route path="/team" element={<Team />} />
//               <Route path="/contacts" element={<Contacts />} />
//               <Route path="/invoices" element={<Invoices />} />
//               <Route path="/form" element={<Form />} />
//               <Route path="/calendar" element={<Calendar />} />
//               <Route path="/bar" element={<Bar />} />
//               <Route path="/pie" element={<Pie />} />
//               <Route path="/line" element={<Line />} />
//               <Route path="/faq" element={<FAQ />} />

//               <Route path="/geography" element={<Geography />} />
//             </Routes>
//           </main>
//         </div>
//       </ThemeProvider>
//     </ColourModeContext.Provider>
//   );
// }

export default withAuthenticator(App);

// export default App;
