import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
// import { Box, Toolbar, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
// import { mockDataInvoices } from "../../../data/mockData";
import Header from "../../../components/Header";
import { useContext, useEffect, useState } from "react";
import { get_bank_accounts, getTransactionList } from "../../../data/plaiddata";
import { AllData } from "../../../data/all_data";

const Transactions = ({ setload }) => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  const isBig = useMediaQuery("(min-width:600px)");
  const columns = [
    // { field: "transaction_id", headerName: "id" },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "merchant_name", headerName: "Store", flex: 1 },
    { field: "iso_currency_code", headerName: "Currency", flex: 1 },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      renderCell: (params) => (
        <Typography
          color={
            params.row.amount >= 0
              ? colours.redAccent[500]
              : colours.greenAccent[500]
          }
        >
          ${Math.abs(params.row.amount)}
        </Typography>
      ),
    },
    { field: "date", headerName: "Date", flex: 1 },
    {
      field: "main_category",
      headerName: "Category",
      flex: 1,
    },
    { field: "account_name", headerName: "Account", flex: 1 },
  ];

  const [transactionList, setTransactionList] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const AData = useContext(AllData);

  useEffect(() => {
    get_bank_accounts().then((data) => {
      console.log(data);
      if (data === undefined) {
        AData.Accounts = [];
      } else {
        AData.Accounts = data;
      }
      const accounts_map = [];
      if (AData.Accounts.length > 0) {
        AData.Accounts.forEach((item) => {
          item.account.forEach((a) => {
            // a = {
            // "persistent_account_id":,
            // "item_id": ,
            // "mask": ,
            // "name": ,
            // "official_name": ,
            // "type": ,
            // "subtype":
            // }
            accounts_map[a.persistent_account_id] = a;
          });
        });
      }
      console.log(accounts_map);
      setAccounts(accounts_map);
      if (AData.TransactionList === null)
        getTransactionList()
          .then((result) => {
            if (result === undefined) {
              setTransactionList(null);
              AData.TransactionList = null;
            } else {
              setTransactionList(result.data);
              AData.TransactionList = result.data;
            }
          })
          .then(() => {
            const trans = [];
            AData.TransactionList.forEach((item) => {
              trans.push({
                ...item,
                account_name: accounts_map[item.account_id].name,
                account_details: accounts_map[item.account_id],
                main_category: item.personal_finance_category.primary,
              });
            });
            setTransactionList(trans);
          });
      else {
        const trans = [];
        AData.TransactionList.forEach((item) => {
          trans.push({
            ...item,
            account_name: accounts_map[item.account_id].name,
            account_details: accounts_map[item.account_id],
            main_category: item.personal_finance_category.primary,
          });
        });
        setTransactionList(trans);
      }
    });
  }, []);

  useEffect(() => {
    setload(!transactionList);
  }, [transactionList]);

  return (
    <Box m="20px">
      <Header title="Transactions" subtitle="List of all Transactions"></Header>
      {transactionList === null && (
        <Typography variant="h5">No Transactions Yet...</Typography>
      )}
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colours.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colours.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colours.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colours.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colours.gray[100]} !important`,
          },
          "$ .MuiCheckbos-root": {
            color: `${colours.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection={isBig}
          rows={transactionList}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          getRowId={(row) => {
            return row.transaction_id;
          }}
          columnVisibilityModel={
            !isBig && {
              merchant_name: false,
              iso_currency_code: false,
              main_category: false,
            }
          }
          ignoreValueFormatterDuringExport={true}
          initialState={{
            sorting: {
              sortModel: [{ field: "date", sort: "desc" }],
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Transactions;
