import { useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColourModeContext, tokens } from "../../../theme";

// import "./style.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Loading = () => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  const colourMode = useContext(ColourModeContext);

  // const loadingScreen = document.querySelector(".loading-screen");
  // const content = document.querySelector(".content");
  // const progress = document.querySelector(".progress");
  // const progressText = document.querySelector(".progress-text");
  const loadingContainer = document.querySelector(".loading-container");

  useEffect(() => {
    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "style-loading.css";

    head.appendChild(link);

    return () => {
      head.removeChild(link);
    };
  });

  // Create floating particles
  function createParticle() {
    const particle = document.createElement("div");
    particle.className = "particle";
    const translateX = (Math.random() - 0.5) * 100;
    // console.log(particle);
    particle.style.setProperty("--translateX", `${translateX}px`);
    particle.style.left = `${Math.random() * 100}%`;
    particle.style.animation = "particleFloat 3s ease-in-out forwards";
    if (loadingContainer != null) loadingContainer.appendChild(particle);

    particle.addEventListener("animationend", () => {
      particle.remove();
    });
  }

  // Generate particles periodically
  // const particleInterval = setInterval(() => {
  //   createParticle();
  //   console.log("createParticle");
  // }, 300);

  // console.log(currentProgress);
  // if (currentProgress > 100) setCurrentProgress(100);
  // if (progress != null) {
  //   progress.style.width = currentProgress + "%";
  //   progressText.textContent = Math.round(currentProgress) + "%";
  // }

  // if (currentProgress === 100) {
  //   // clearInterval(interval);
  //   clearInterval(particleInterval);
  //   setTimeout(hideLoadingScreen, 500);
  //   DData.Balance = balance;
  //   DData.StaticMetrics = staticMetrics;
  //   DData.TransactionList = transaction_list;
  //   DData.TransactionsLine = transaction_line;
  // }

  // useEffect(() => {
  //   console.log(currentProgress[0]);
  //   if (progress != null) {
  //     progress.style.width = currentProgress[0] + "%";
  //     progressText.textContent = Math.round(currentProgress[0]) + "%";
  //   }
  //   if (currentProgress[0] == 100) {
  //     // clearInterval(interval);
  //     clearInterval(particleInterval);
  //     // setTimeout(hideLoadingScreen, 500);
  //   }
  // }, [currentProgress]);

  // Simulate loading progress
  // let currentProgress = 0;
  // const interval = setInterval(() => {
  //   currentProgress += Math.random() * 12;
  //   if (currentProgress > 100) currentProgress = 100;
  //   if (progress != null) {
  //     progress.style.width = currentProgress + "%";
  //     progressText.textContent = Math.round(currentProgress) + "%";

  //     if (currentProgress === 100) {
  //       clearInterval(interval);
  //       clearInterval(particleInterval);
  //       setTimeout(hideLoadingScreen, 500);
  //     }
  //   }
  // }, 200);

  // Add mouse movement interaction
  // document.addEventListener("mousemove", (e) => {
  //   if (loadingScreen != null) {
  //     if (loadingScreen.style.opacity !== "0") {
  //       const ring = document.querySelector(".loader-ring");
  //       const rect = ring.getBoundingClientRect();
  //       const centerX = rect.left + rect.width / 2;
  //       const centerY = rect.top + rect.height / 2;

  //       const deltaX = (e.clientX - centerX) / 30;
  //       const deltaY = (e.clientY - centerY) / 30;

  //       ring.style.transform = `perspective(1000px) rotateX(${deltaY}deg) rotateY(${-deltaX}deg)`;
  //     }
  //   }
  // });

  // function hideLoadingScreen() {
  //   loadingScreen.style.opacity = "0";
  //   setTimeout(() => {
  //     loadingScreen.style.display = "none";
  //     content.classList.add("visible");
  //   }, 800);
  // }

  return (
    <div class="loading-screen">
      <div class="loading-container">
        <div class="loader-ring">
          <div class="loader-core"></div>
        </div>
        {/* <div class="progress-container">
          <div class="progress-text">0%</div>
          <div class="progress-bar">
            <div class="progress"></div>
          </div>
        </div> */}
        <div class="loading-text">Loading Dashboard</div>
      </div>
    </div>
  );
};

export default Loading;
