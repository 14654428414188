import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  Box,
  Drawer,
  IconButton,
  ListItemButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import { Box, Icon, IconButton, Typography, useTheme } from "@mui/material";
// import Link from "@mui/material/Link";
import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ListItem from "@mui/material/ListItem";

import { Amplify } from "aws-amplify";
import awsExports from "../../../aws-exports";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getUser } from "../../../data/userControl";
import { getCredentials } from "../../../data/fetchCognito";
// import { Navigate } from "react-router-dom";

Amplify.configure(awsExports);

const DrawerMenu = () => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  const screen_size = useMediaQuery("(min-width:1200px)");

  const [selected, setSelected] = useState("Dashboard");

  const { signOut } = useAuthenticator((context) => [context.user]);

  return (
    // <Menu iconShape="square">
    <Box
      padding={"20% 0 10% 0"}
      sx={{
        a: {
          color: colours.gray[100],
          textDecoration: "none !important",
        },
      }}
    >
      <ListItemButton
        style={{
          color: colours.gray[100],
          paddingBottom: "20px",
          paddingTop: "20px",
        }}
      >
        <HomeOutlinedIcon />
        <Link to={"/"}>{"Dashboard"}</Link>
      </ListItemButton>

      <ListItemButton
        style={{
          color: colours.gray[100],
          paddingBottom: "20px",
          paddingTop: "20px",
        }}
      >
        <ReceiptOutlinedIcon />
        <Link to={"/transactions"}>{"Transactions"}</Link>
      </ListItemButton>

      <ListItemButton
        style={{
          color: colours.gray[100],
          paddingBottom: "20px",
          paddingTop: "20px",
        }}
        onClick={() => {
          signOut();
        }}
      >
        <ExitToAppIcon />
        <Typography>Sign Out</Typography>
      </ListItemButton>
    </Box>
  );
};

export default DrawerMenu;
