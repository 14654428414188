import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { mockLineData } from "../data/mockData";
import { colors, useTheme } from "@mui/material";
import moment from "moment/moment";
import { tokens } from "../theme";

// const data = [
//   {
//     id: "TD FIRST CLASS TRAVEL VISA INFINITE CARD",
//     color: "#8884d8",
//     data: [
//       { x: "2024-10-21", y: 295.15 },
//       { x: "2024-10-22", y: 3.84 },
//       { x: "2024-10-24", y: 18.99 },
//       { x: "2024-10-28", y: 31.43 },
//       { x: "2024-10-29", y: 27.58 },
//     ],
//   },
//   {
//     id: "TD STUDENT CHEQUING ACCOUNT",
//     color: "#82ca9d",
//     data: [
//       { x: "2024-10-21", y: 250 },
//       { x: "2024-10-24", y: 452.76 },
//       { x: "2024-10-28", y: 19.39 },
//     ],
//   },
// ];

const SimpleLineChart = ({ isDashboard = false, data }) => {
  //   console.log(data);
  // Merge the data by dates
  const line_colors = {
    dark: [
      "#4CAF50", // Vibrant green
      // "#F44336", // Bright red
      "#9C27B0", // Bold purple
      "#00BCD4", // Bright cyan
      "#FFC107", // Bright amber
      "#E91E63", // Vibrant pink
      "#8BC34A", // Bright lime
      "#3F51B5", // Vivid indigo
      "#CDDC39", // Bright lime green
      "#FFEB3B", // Bright yellow
      "#2196F3", // Existing vibrant blue
      "#FF9800", // Existing bright orange
    ],
    light: [
      "#64B5F6", // Light sky blue
      "#FFB74D", // Soft orange
      "#81C784", // Light green
      // "#E57373", // Soft red
      "#BA68C8", // Soft purple
      "#4DD0E1", // Soft cyan
      "#FFD54F", // Soft amber
      "#F06292", // Soft pink
      "#AED581", // Soft lime
      "#7986CB", // Soft indigo
      "#FFF176", // Soft yellow
      "#2196F3", // Existing vibrant blue
      "#FF9800", // Existing bright orange
    ],
  };
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  const mergedData = [];
  const dateSet = new Set();

  data.forEach((dataset) => {
    dataset.data.forEach((entry) => {
      dateSet.add(entry.x);
    });
  });

  Array.from(dateSet)
    .sort()
    .forEach((date) => {
      const mergedEntry = { date };
      data.forEach((dataset) => {
        const matchingEntry = dataset.data.find((entry) => entry.x === date);
        mergedEntry[dataset.id] = matchingEntry
          ? parseFloat(matchingEntry.y.toFixed(2)) // Force 2 decimal places
          : "0.00";
      });
      mergedData.push(mergedEntry);
    });

  return (
    <ResponsiveContainer>
      <LineChart data={mergedData} margin={{ top: 50, right: 30, bottom: 50 }}>
        {/* <CartesianGrid stroke strokeDasharray={false} /> */}
        <XAxis
          dataKey="date"
          tickFormatter={(date) => moment(date).format("MMM DD-YY")}
        />
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="bottom" />

        {data.map((dataset) => (
          <Line
            key={dataset.id}
            type="monotone"
            dataKey={dataset.id}
            stroke={line_colors[theme.palette.mode][dataset.color]}
            strokeWidth={2}
            dot={false}
          />
        ))}

        <Brush
          tickFormatter={(date) => moment(date).format("ll")}
          dataKey="date"
          fill={colours.primary[400]}
        >
          <LineChart
            data={mergedData}
            margin={{ top: 50, right: 30, bottom: 50 }}
          >
            {data.map((dataset) => (
              <Line
                key={dataset.id}
                type="monotone"
                dataKey={dataset.id}
                stroke={line_colors[theme.palette.mode][dataset.color]}
                strokeWidth={1}
                opacity={0.55}
                dot={false}
              />
            ))}
          </LineChart>
        </Brush>
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SimpleLineChart;
