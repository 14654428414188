import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";
// import { defaultAnimate } from "@nivo/core";

const StatBox = ({
  title,
  subtitle,
  icon,
  progress,
  increase,
  sx = null,
  _inc = true,
}) => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  return (
    <Box width="100%" m="0 30px" sx={sx}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          {icon}
          <Typography
            varieant="h4"
            fontWeight="bold"
            sx={{ color: colours.gray[100] }}
          >
            {title}
          </Typography>
        </Box>
        {progress && (
          <Box>
            <ProgressCircle progress={progress} increase={_inc} />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography varieant="h5" sx={{ color: colours.greenAccent[100] }}>
          {subtitle}
        </Typography>
        {increase && (
          <Typography
            varieant="h5"
            fontStyle="italic"
            sx={{
              color: _inc ? colours.greenAccent[600] : colours.redAccent[600],
            }}
          >
            {_inc ? "+" : "-" + increase + "%"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default StatBox;
